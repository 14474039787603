.App {
  width: 100%;
  height: 100vh;
}

:root {
  --fnp-blue: #01b9fe;
  --fnp-green: #61cc40;
  --fnp-grey: #797979;
  --fnp-footer: #1F344C;
  --fnp-card: #013657;
  --fnp-blue: #046fad;
  --fnp-darkblue: #1f344c;
  --fnp-lightgrey: #bcbec0;
  --fnp-image-background: #ececec;
  --fnp-red: #f87978;
  --fnp-yellow: #f5bd00;
  --fnp-orange: #fe7200;
  --fnp-cyan: #189477;
  --fnp-pink: #d0175d;
  --fnp-youtube: #ff0000;
  --fnp-facebook: #3b5998;
  --fnp-cardgreen: #acc33e;
  --fnp-cardyellow: #fecc38;
  --fnp-header: #057abf;
}